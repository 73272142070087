<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">
                {{ $t("ESTABLISHMENTS.ESTABLISHMENTS_LIST") }}
              </h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="addEstablishment()"
                v-if="$currentUserCan($permissions.PERM_CREATE_ESTABLISHMENTS)"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("ESTABLISHMENTS.ADD_ESTABLISHMENT")
                }}</span>
              </base-button>
            </div>
          </div>
        </template>

        <establishment-list-table />
      </card>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import EstablishmentListTable from "./partials/EstablishmentListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    EstablishmentListTable,
    [Button.name]: Button,
  },

  methods: {
    addEstablishment() {
      this.$router.push({ name: "Add Establishment" });
    },
  },
};
</script>
